import React from "react"

function Footer() {
  return (
    <div>
      <footer className="py-2" style={{ backgroundColor: "#2B2B2B" }}>
        <div className="container">
          <p>RADAR Studio, LLC | © Copyright 2019</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
