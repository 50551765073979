import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { useAlert } from "react-alert"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function End () {
  const alert = useAlert()
  const defaultState = {
    name: null,
    email: null,
    message: null,
  }

  const [state, setState] = useState(defaultState)

  const onChange = e => setState({ ...state, [e.target.name]: e.target.value })

  const onSubmit = e => {
    e.preventDefault()
    const { name, email, message } = state
    const form = e.target

    if (!name || !email || !message)
      return alert.error("All fields are required")

    fetch(form.action, {
      method: form.method,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        name,
        email,
        message,
      }),
    })
      .then(() => {
        alert.success("Message sent!")
        form.reset()
        setState(defaultState)
      })
      .catch(error => alert.error("An error occurred. Please try again."))
  }

  return (
    <div
      className='container-fluid'
      style={{ backgroundColor: "#29AB87", color: "white" }}
    >
      <div className='container'>
        <div className='flexbox'>
          <Fade left>
            <form
              id='contact'
              name='contact'
              method='POST'
              action='/'
              data-netlify='true'
              netlify-honeypot='honey'
              onSubmit={onSubmit}
            >
              <h1 className='text-center my-5'>Get Support</h1>
              <input type='hidden' name='form-name' value='contact' />
              <p className='u-visually-hidden'>
                <label>
                  Don’t fill this out if you're human: <input name='honey' />
                </label>
              </p>
              <div className='row my-4'>
                <div className='col-lg-6 mb-4'>
                  <input
                    type='name'
                    className='inputBox'
                    placeholder='Your Name'
                    name='name'
                    onChange={onChange}
                  />
                </div>
                <div className='col-lg-6 mb-4'>
                  <input
                    type='email'
                    className='inputBox'
                    placeholder='Your Email'
                    name='email'
                    onChange={onChange}
                  />
                </div>
              </div>
              <textarea
                rows={2}
                className='inputBox mb-4'
                placeholder='Your Message'
                name='message'
                onChange={onChange}
              />
              <button className='btn btn-light mb-4 py-3' type='submit'>
                Submit
              </button>
            </form>
          </Fade>
        </div>
      </div>
    </div>
  )
}
