import React from "react"
import Fade from "react-reveal/Fade"

function TeamItem({ src, title, description }) {
  return (
    <Fade bottom>
      <div className="col-lg-4 my-4 col-sm-12" style={{ textAlign: "center" }}>
        <img src={src} className="img-fluid rounded-circle" width="200px" alt={title} />
        <h3 className="mt-5">{title}</h3>
        <p>
          {description}
        </p>
      </div>
    </Fade>
  )
}

export default TeamItem
