import React from "react"
import "../global.css"
import LandingPage from "../subpages/LandingPage"
import Services from "../subpages/Services"
import Team from "../subpages/Team"
import End from "../subpages/End"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"
import { positions, Provider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"

const options = {
  timeout: 7000,
  position: positions.BOTTOM_CENTER,
  offset: "80px"
}

function Index() {
  return (
    <Provider template={AlertTemplate} {...options}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RADAR Studio, LLC. :: Software Development Solutions</title>
        <link rel="canonical" href="https://radar.llc" />
      </Helmet>
      <LandingPage />
      <Services />
      <Team />
      <End />
      <Footer />
    </Provider>
  )
}

export default Index
